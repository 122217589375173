.currency-input-container {
  display: flex;
  align-items: center;
  position: relative;
}

.currency-symbol {
  position: absolute;
  left: 7px;
  color: #888;
  font-size: 12px;
  pointer-events: none;
  z-index: 5;
}

.inputGroup {
  text-align: center;
  padding-left: 36px;
  width: 100%;
}